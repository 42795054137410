<template>
  <div class="container">
    <el-tabs @tab-click="selectTab" type="border-card">
      <el-tab-pane label="Overall Subscription Revenue Status">
        <div class="pane_box">
          <el-form
            ref="searchRevenue"
            style="flex: 1"
            :inline="true"
            :model="revenueSearch"
            class="demo-form-inline"
          >
            <el-form-item label="Search Date">
              <el-date-picker
                @change="searchRevenue"
                v-model="revenueSearch.time"
                :default-value="timeDefaultShow"
                type="daterange"
                unlink-panels
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
              />
            </el-form-item>
            <el-radio-group v-model="tabPositionRevenue" class="radio_group">
              <el-radio-button label="day">last day</el-radio-button>
              <el-radio-button label="week">last week</el-radio-button>
              <el-radio-button label="month">last month</el-radio-button>
            </el-radio-group>
          </el-form>
        </div>
        <el-table
          :data="tableData"
          :span-method="objectSpanMethod"
          border
          style="width: 100%; margin-bottom: 10px"
        >
          <el-table-column prop="id" label="Statistics"></el-table-column>
          <el-table-column prop="name" label="Area"></el-table-column>
          <el-table-column prop="currency" label="Currency"></el-table-column>
          <el-table-column prop="value" label="Revenue"></el-table-column>
        </el-table>
        <!-- 折线图 -->

        <div v-if="x_axis.length>0" id="revenue" class="chart_box"></div>
        <div v-else  class="chart_box">暂无数据</div>
      </el-tab-pane>
      <el-tab-pane label="Subscription rate from user point view">
        <div class="pane_box">
          <el-form
            ref="searchUser"
            style="flex: 1"
            :inline="true"
            :model="user_Search"
            class="demo-form-inline"
          >
            <el-form-item label="Search Date">
              <el-date-picker
                @change="SearchUser"
                v-model="user_Search.time"
                :default-value="user_timeDefault"
                type="daterange"
                unlink-panels
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
              />
            </el-form-item>

            <el-radio-group
              v-model="tabPositionSubFromUser"
              class="radio_group"
            >
              <el-radio-button label="day">last day</el-radio-button>
              <el-radio-button label="week">last week</el-radio-button>
              <el-radio-button label="month">last month</el-radio-button>
            </el-radio-group>
          </el-form>
        </div>
        <el-table
          :data="user_table_cu"
          :span-method="userSpanMethod"
          border
          style="width: 100%; margin-bottom: 10px"
        >
          <el-table-column
            prop="id"
            label="Statistics"
            width="180"
          ></el-table-column>
          <el-table-column prop="type" label="Total number of register users">
            <el-table-column prop="name" label="Area"></el-table-column>
            <el-table-column
              prop="registernum"
              label="Amount"
            ></el-table-column>
          </el-table-column>
          <el-table-column prop="type" label="Total number of subscribers">
            <el-table-column prop="name" label="Area"></el-table-column>
            <el-table-column prop="subnum" label="Amount"></el-table-column>
          </el-table-column>
          <el-table-column prop="type" label="Subscription rate">
            <el-table-column prop="name" label="Area"></el-table-column>
            <el-table-column prop="rate" label="Rate"></el-table-column>
          </el-table-column>
        </el-table>
        <!-- 折线图 -->
        <div id="user" class="chart_box"></div>
      </el-tab-pane>
      <el-tab-pane label="Subscription rate from device point view">
        <div class="pane_box">
          <el-form
            ref="from_device"
            style="flex: 1"
            :inline="true"
            :model="device_Search"
            class="demo-form-inline"
          >
            <el-form-item label="Search Date">
              <el-date-picker
                @change="SearchDevice"
                v-model="device_Search.time"
                :default-value="device_timeDefault"
                type="daterange"
                unlink-panels
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
              />
            </el-form-item>
            <el-radio-group v-model="tabPositionDevice" class="radio_group">
              <el-radio-button label="day">last day</el-radio-button>
              <el-radio-button label="week">last week</el-radio-button>
              <el-radio-button label="month">last month</el-radio-button>
            </el-radio-group>
          </el-form>
        </div>
        <el-table
          :data="user_table_cu"
          :span-method="userSpanMethod"
          border
          style="width: 100%; margin-bottom: 10px"
        >
          <el-table-column
            prop="id"
            label="Statistics"
            width="180"
          ></el-table-column>
          <el-table-column prop="type" label="The total number of device">
            <el-table-column prop="name" label="Area"></el-table-column>
            <el-table-column
              prop="registernum"
              label="Amount"
            ></el-table-column>
          </el-table-column>
          <el-table-column prop="type" label="subscribed devices">
            <el-table-column prop="name" label="Area"></el-table-column>
            <el-table-column prop="subnum" label="Amount"></el-table-column>
          </el-table-column>
          <el-table-column prop="type" label="Subscription rate">
            <el-table-column prop="name" label="Area"></el-table-column>
            <el-table-column prop="rate" label="Rate"></el-table-column>
          </el-table-column>
        </el-table>
        <div id="device" class="chart_box"></div>
      </el-tab-pane>
      <el-tab-pane label="Subscription rate as per camera">
        <div class="pane_box">
          <el-form
            ref="from_camera"
            style="flex: 1"
            :inline="true"
            :model="camera_Search"
            class="demo-form-inline"
          >
            <el-form-item label="Search Date">
              <el-date-picker
                @change="SearchCamera"
                v-model="camera_Search.time"
                :default-value="camera_timeDefault"
                type="daterange"
                unlink-panels
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
              />
            </el-form-item>
            <el-radio-group v-model="tabPositionCamera" class="radio_group">
              <el-radio-button label="day">last day</el-radio-button>
              <el-radio-button label="week">last week</el-radio-button>
              <el-radio-button label="month">last month</el-radio-button>
            </el-radio-group>
            <el-radio-group v-model="selectRegion" class="radio_group">
              <el-radio-button
                v-for="item in regionList"
                :key="item.value"
                :label="item.label"
                >{{ item.value }}</el-radio-button
              >
            </el-radio-group>
          </el-form>
        </div>
        <div style="display: flex; justify-content: space-around">
          <el-table
            border
            v-bind:data="camera_table_cu"
            :span-method="userSpanMethod"
            style="width: 100%"
          >
            <el-table-column :label="selectRegion">
              <!-- <template v-slot="scope">
                <span>{{ scope.row.camera }}</span>
              </template> -->
            </el-table-column>
            <el-table-column label="device name">
              <template v-slot="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="The total number of device">
              <template v-slot="scope">
                <span>{{ scope.row.total }}</span>
              </template>
            </el-table-column>
            <el-table-column label="subscribed devices">
              <template v-slot="scope">
                <span>{{ scope.row.subnum }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Subscription rate">
              <template v-slot="scope">
                <span>{{ scope.row.rate }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div id="camera" class="chart_box"></div>
      </el-tab-pane>
      <el-tab-pane label="Cancel situation from user point view">
        <div class="chart_box">暂无接口信息</div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script type="text/ecmascript-6">
import * as echarts from "echarts";

export default {
  name: "",
  props: {},
  setup() {
    return {};
  },
  mixins: [],
  components: {},
  data() {
    return {
      selectRegion: "US",
      regionList: [],
      tableData: [
        {
          id: "Nooie Total Cloud service Revenue",
          name: "CN",
          value: "0",
          currency: "CNY",
        },
        {
          id: "Nooie Total Cloud service Revenue",
          name: "US",
          value: "0",
          currency: "USD",
        },
        {
          id: "Nooie Total Cloud service Revenue",
          name: "EU",
          value: "0",
          currency: "EUR",
        },
        {
          id: "Nooie Total Cloud service Revenue",
          name: "US+EU",
          value: "0",
          currency: "USD",
        },
      ],
      tabPositionSubFromUser: "week",
      tabPositionRevenue: "week",
      tabPositionDevice: "week",
      tabPositionCamera: "week",
      timeDefaultShow: "",
      revenueSearch: {
        time: "",
      },
      x_axis: [],
      cn_series: [],
      eu_series: [],
      us_series: [],
      us_eu_series: [],
      // 用户
      user_Search: {
        time: "",
      },
      user_timeDefault: "",
      user_table: [
        {
          id: "Subscription rate from user point view",
          name: "CN",
          registernum: "0",
          subnum: "0",
          rate: "0",
        },
        {
          id: "Subscription rate from user point view",
          name: "US",
          registernum: "0",
          subnum: "0",
          rate: "0",
        },
        {
          id: "Subscription rate from user point view",
          name: "EU",
          registernum: "0",
          subnum: "0",
          rate: "0",
        },
        {
          id: "Subscription rate from user point view",
          name: "US+EU",
          registernum: "0",
          subnum: "0",
          rate: "0",
        },
      ],
      series_data: [
        {
          name: "CN",
          type: "line",
          stack: "Total",
          itemStyle: {
            normal: {
              color: "rgb(239, 28, 38)",
              lineStyle: {
                color: "rgb(239, 28, 38)",
              },
            },
          },
          data: null,
        },
        {
          name: "EU",
          type: "line",
          stack: "Total",
          itemStyle: {
            normal: {
              color: "rgb(110, 112, 121)",
              lineStyle: {
                color: "rgb(110, 112, 121)",
              },
            },
          },
          data: null,
        },
        {
          name: "US",
          type: "line",
          stack: "Total",
          itemStyle: {
            normal: {
              color: "rgb(2, 39, 96)",
              lineStyle: {
                color: "rgb(2, 39, 96)",
              },
            },
          },
          data: null,
        },
        {
          name: "US+EU",
          type: "line",
          stack: "Total",
          itemStyle: {
            normal: {
              color: "rgb(61, 171, 219)",
              lineStyle: {
                color: "rgb(61, 171, 219)",
              },
            },
          },
          data: null,
        },
      ],
      // 设备角度
      device_timeDefault: "",
      device_Search: {
        time: "",
      },
      device_table: [
        {
          id: "Subscription rate from device pointview",
          name: "CN",
          registernum: "0.0",
          subnum: "0.0",
          rate: "0.0",
        },
        {
          id: "Subscription rate from device pointview",
          name: "US",
          registernum: "0.0",
          subnum: "0.0",
          rate: "0.0",
        },
        {
          id: "Subscription rate from device pointview",
          name: "EU",
          registernum: "0.0",
          subnum: "0.0",
          rate: "0.0",
        },
        {
          id: "Subscription rate from device pointview",
          name: "US+EU",
          registernum: "0.0",
          subnum: "0.0",
          rate: "0.0",
        },
      ],
      // 相机角度
      camera_Search: {
        time: "",
      },
      camera_timeDefault: "",
      camera_table: [],
      camera_char_data: [],
      myChart: null,
    };
  },
  computed: {
    user_table_cu() {
      let user_table = JSON.parse(JSON.stringify(this.user_table));
      for (let i in user_table) {
        if (user_table[i].subnum == 0) {
          user_table[i].rate = 0 + "%";
        } else {
          user_table[i].rate =
            Math.round(
              (user_table[i].subnum / user_table[i].registernum) * 10000
            ) /
              100 +
            "%";
        }
      }
      return user_table;
    },
    device_table_cu() {
      let device_table = JSON.parse(JSON.stringify(this.device_table));
      for (let i in device_table) {
        if (device_table[i].subnum == 0) {
          device_table[i].rate = 0 + "%";
        } else {
          device_table[i].rate =
            Math.round(
              (device_table[i].subnum / device_table[i].registernum) * 10000
            ) /
              100 +
            "%";
        }
      }
      return device_table;
    },
    camera_table_cu() {
      let camera_table = JSON.parse(JSON.stringify(this.camera_table));
      for (let i in camera_table) {
        if (
          camera_table[i].subnum == undefined ||
          camera_table[i].subnum == 0
        ) {
          camera_table[i].rate = 0 + "%";
        } else {
          camera_table[i].rate =
            Math.round(
              (camera_table[i].subnum / camera_table[i].total) * 10000
            ) /
              100 +
            "%";
        }
      }
      return camera_table;
    },
  },
  watch: {
    tabPositionRevenue(val) {
      this.tabDateType(val, "timeDefaultShow", () => {
        this.revenueSearch.time = this.timeDefaultShow;
        this.revenueDay();
      });
    },
    tabPositionSubFromUser(val) {
      this.tabDateType(val, "user_timeDefault", () => {
        this.user_Search.time = this.user_timeDefault;
        this.UserDay();
      });
    },
    tabPositionDevice(val) {
      this.tabDateType(val, "device_timeDefault", () => {
        this.device_Search.time = this.device_timeDefault;
        this.deviceDay();
      });
    },
    tabPositionCamera(val) {
      this.tabDateType(val, "camera_timeDefault", () => {
        this.camera_Search.time = this.camera_timeDefault;
        this.cameraDay();
      });
    },
    selectRegion(val) {
      let region = val.toLowerCase().replace(/\+/g, "_");
      this.getCamera(region).then(() => {
        this.getCameraSub(region);
      });
    },
  },

  methods: {
    tabDateType(val, type, fn) {
      if (val == "day") {
        this[type] = [new Date(), new Date()];
      } else if (val == "week") {
        this[type] = [new Date() - 3600 * 1000 * 24 * 7, new Date()];
      } else {
        this[type] = [new Date() - 3600 * 1000 * 24 * 30, new Date()];
      }
      fn();
    },
    selectTab(item) {
      if (item.index == 0) {
        this.tabDateType("week", "timeDefaultShow", () => {
          this.revenueSearch.time = this.timeDefaultShow;
          this.searchRevenue();
        });
      }
      if (item.index == 1) {
        this.tabDateType("week", "user_timeDefault", () => {
          this.user_Search.time = this.user_timeDefault;
          this.SearchUser();
        });
      }
      if (item.index == 2) {
        this.tabDateType("week", "device_timeDefault", () => {
          this.device_Search.time = this.device_timeDefault;

          this.SearchDevice();
        });
      }
      if (item.index == 3) {
        this.tabDateType("week", "camera_timeDefault", () => {
          this.camera_Search.time = this.camera_timeDefault;
          this.SearchCamera();
        });
      }
    },
    // 收入
    searchRevenue() {
      this.getRevenue();
      this.revenueDay();
    },
    getRevenue() {
      this.api
        .BoardViewUser_Revenue({
          start: "",
          end: "",
        })
        .then((res) => {
          if (res.data.code == 200) {
            let result = res.data.data.data;
            this.tableData.forEach((item) => {
              let o = item.name.toLowerCase().replace(/\+/g, "_");
              if (result[o].length != 0) {
                if (item.name == "US+EU") {
                  item.currency = result[o][0].currency;
                  let eur = parseFloat(
                    (result[o][1].value * 1.0639).toFixed(2)
                  );
                  let usd = parseFloat(result[o][0].value);
                  let total = eur + usd;
                  item.value = total;
                } else {
                  item.value = result[o][0].value;
                }
              }
            });
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    revenueDay() {
      this.api
        .BoardViewUser_Revenue_Day({
          start: parseInt(
            new Date(this.revenueSearch.time[0]).getTime() / 1000
          ),
          end: parseInt(new Date(this.revenueSearch.time[1]).getTime() / 1000),
        })
        .then((res) => {
          if (res.data.code == 200) {
            let result = res.data.data;
            if (result.x_axis.value == null) {
              return;
            }
            this.x_axis = result.x_axis.value;
            let initData = (data, exchangeRate, item) => {
              let valArr = [];
              data[item].forEach((val) => {
                if (val.length !== 0) {
                  if (item == "us_eu") {
                    let eur = val[0]
                      ? parseFloat((val[0].value * exchangeRate).toFixed(2))
                      : 0;
                    let usd = val[1] ? parseFloat(val[1].value) : 0;
                    let total = eur + usd;
                    valArr.push(total);
                  } else {
                    valArr.push(val[0] ? val[0].value * exchangeRate : 0);
                  }
                } else {
                  valArr.push(0);
                }
              });
              this[item + "_series"] = valArr;
            };
            let arr = [];
            Object.keys(result.data).forEach((item) => {
              let exchangeRate;
              switch (item) {
                case "cn":
                  exchangeRate = 0.1525;
                  break;
                case "eu":
                  exchangeRate = 1.0639;
                  break;
                case "us_eu":
                  exchangeRate = 1.0639;
                  break;
                default:
                  exchangeRate = 1;
                  break;
              }
              initData(result.data, exchangeRate, item);
              arr.push(item.toUpperCase().replace(/_/g, "+"));
            });
            this.series_data.forEach((item) => {
              let o = item.name.toLowerCase().replace(/\+/g, "_") + "_series";
              item.data = this[o];
            });
            // this.renderChart(res.data.data, "revenue");
            let params = {
              chartDom: "revenue",
              legend_data: arr,
              xAxis_data: this.x_axis,
              series_data: this.series_data,
            };
            this.initChart(params);
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    renderChart(data, id) {
      let result = data;
      // 更新横轴
      this.x_axis = result.x_axis.value;
      let arr = [];
      Object.keys(result.data).forEach((item) => {
        arr.push(item.toUpperCase().replace(/_/g, "+"));
        this[item + "_series"] = result.data[item]
          ? result.data[item][0].value
          : [];
      });
      this.series_data.forEach((item) => {
        let o = item.name.toLowerCase().replace(/\+/g, "_") + "_series";
        item.data = this[o];
      });
      let params = {
        chartDom: id,
        legend_data: arr,
        xAxis_data: this.x_axis,
        series_data: this.series_data,
      };
      this.initChart(params);
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 表格样式
      if (columnIndex === 0) {
        if (rowIndex % 4 === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    // 用户
    userSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 用户表格样式
      if (columnIndex === 0) {
        if (rowIndex % 4 === 0) {
          return {
            rowspan: 4,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    SearchUser() {
      this.SubscriptionRate();
      this.UserDay();
      this.BoardViewUser();
    },
    BoardViewUser() {
      this.api
        .BoardViewUser({
          start: parseInt(new Date(this.user_Search.time[0]).getTime() / 1000),
          end: parseInt(new Date(this.user_Search.time[1]).getTime() / 1000),
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.user_table.forEach((item) => {
              let o = item.name.toLowerCase().replace(/\+/g, "_");
              item.registernum = res.data.data[o];
            });

            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    SubscriptionRate() {
      this.api
        .BoardViewUser_Subscription({
          start: "",
          end: "",
        })
        .then((res) => {
          if (res.data.code == 200) {
            let result = res.data.data;
            this.user_table.forEach((item) => {
              item.subnum = result[item.name.toLowerCase().replace(/\+/g, "_")];
            });
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },

    UserDay() {
      this.api
        .BoardViewUser_Day({
          start: parseInt(new Date(this.user_Search.time[0]).getTime() / 1000),
          end: parseInt(new Date(this.user_Search.time[1]).getTime() / 1000),
        })
        .then((res) => {
          if (res.data.code == 200) {
            // 更新横轴
            this.renderChart(res.data.data, "user");
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    deviceDay() {
      this.api
        .BoardViewUser_device_Day({
          start: parseInt(
            new Date(this.device_Search.time[0]).getTime() / 1000
          ),
          end: parseInt(new Date(this.device_Search.time[1]).getTime() / 1000),
        })
        .then((res) => {
          if (res.data.code == 200) {
            // 更新横轴
            this.renderChart(res.data.data, "device");
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    getDevice() {
      this.api
        .BoardViewUser_device({
          start: "",
          end: "",
        })
        .then((res) => {
          this.device_table.forEach((item) => {
            let o = item.name.toLowerCase().replace(/\+/g, "_");
            item.registernum = res.data.data[o];
          });
        });
    },
    getDeviceSub() {
      this.api
        .BoardViewUser_device_sub({
          start: "",
          end: "",
        })
        .then((res) => {
          this.device_table.forEach((item) => {
            let o = item.name.toLowerCase().replace(/\+/g, "_");
            item.subnum = res.data.data[o];
          });
        });
    },
    // 设备角度
    SearchDevice() {
      this.deviceDay();
      this.getDevice();
      this.getDeviceSub();
    },
    async getCamera(type) {
      let res = await this.api.BoardViewUser_camera({
        start: "",
        end: "",
      });
      if (res.data.code == 200) {
        let arr = [];
        Object.keys(res.data.data).forEach((item) => {
          let o = item.toUpperCase().replace(/_/g, "+");
          arr.push({
            label: o,
            value: o,
          });
        });
        this.regionList = arr;
        this.initCameraData(res.data.data, type);
        return;
      }
      this.$message.error("获取失败" + res.data.msg);
    },
    initCameraData(data, type) {
      let o = data[type];
      if (o == null) {
        return;
      }
      let arr = [];
      let arr_chart = [];
      Object.keys(o).forEach((item, index) => {
        arr.push({
          name: item,
          total: o[item] ? o[item] : 0,
        });
      });
      this.camera_table = arr;
    },
    initCameraDataSub(data, type) {
      let o = data[type];
      if (o == null) {
        return;
      }
      this.camera_table.forEach((item) => {
        item.subnum = o[item.name] ? o[item.name] : 0;
      });
    },

    getCameraSub(type) {
      this.api
        .BoardViewUser_camera_sub({
          start: "",
          end: "",
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.initCameraDataSub(res.data.data, type);
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    CameraDay() {
      this.api
        .BoardViewUser_camera_Day({
          start: parseInt(
            new Date(this.camera_Search.time[0]).getTime() / 1000
          ),
          end: parseInt(new Date(this.camera_Search.time[1]).getTime() / 1000),
        })
        .then((res) => {
          if (res.data.code == 200) {
            // 更新横轴
            let result = res.data.data;
            this.x_axis = result.x_axis.value;
            let o = this.selectRegion.toLowerCase().replace(/\+/g, "_");
            let arr = [];
            let arr_chart = [];
            Object.keys(result.data[o]).forEach((item,index) => {
              arr_chart.push({
                name: item,
                type: "line",
                stack: "Total",
                itemStyle: {
                  normal: {
                    color: "#" + 168 * index,
                    lineStyle: {
                      color: "#" +168 * index,
                    },
                  },
                },
                data: null,
              });
            });
            this.camera_char_data = arr_chart;
            this.camera_char_data.forEach((item) => {
              item.data = result.data[o][item.name + ""][0].value;
              arr.push(item.name);
            });

            let params = {
              chartDom: "camera",
              legend_data: arr,
              xAxis_data: this.x_axis,
              series_data: this.camera_char_data,
            };
            this.initChart(params);
            return;
          }
          this.$message.error("获取失败" + res.data.msg);
        });
    },
    // 相机角度
    SearchCamera() {
      this.getCamera("us").then(() => {
        this.getCameraSub("us");
        this.CameraDay();
      });
    },
    initChart(params) {
      var chartDom = document.getElementById(params.chartDom);
      let myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          // text: 'Nooie看板',
          // left: "center",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: params.legend_data,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: params.xAxis_data,
        },
        yAxis: {
          type: "value",
        },
        series: params.series_data,
      };

      option && myChart.setOption(option);
    },
  },
  filters: {},
  mounted() {
    this.timeDefaultShow = [new Date() - 3600 * 1000 * 24 * 7, new Date()];
    this.revenueSearch.time = this.timeDefaultShow;
    this.searchRevenue();
  },
  created() {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.pane_box {
  margin-top: 10px;
}

.chart_box {
  width: 100%;
  height: 400px;
}

.radio_group {
  margin-left: 10px;
}

.selcet_region {
  margin-left: 20px;
  flex: 1, ;
}

.selcet_box {
  width: 200px;
  margin-left: 10px;
}
</style>
